import {
  AppBar,
  Button,
  Container,
  Grid,
  Icon,
  SvgIcon,
  Toolbar,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import {
  FacebookButton,
  LinkedinButton,
  YoutubeButton,
  TikTokButton,
  InstagramButton,
} from "../UI/Buttons/SocialsButtons";
import { useTheme } from "@emotion/react";
import QualinoteLogo from "../../assets/images/Icons/logo_horizontal_qualinote.svg";

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

const Actions = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleContactClick = () => {
    const contactSection = document.getElementById("contact-section");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Grid container direction="row-reverse" alignItems="center" gap={1.5}>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleContactClick}
        >
          Contactez-nous
        </Button>
      </Grid>
      <Grid item />
      <Grid item>
        <TikTokButton size={"1.5em"} />
      </Grid>
      <Grid item>
        <InstagramButton size={"1.5em"} />
      </Grid>
      <Grid item>
        <FacebookButton size={"1.5em"} />
      </Grid>
      <Grid item>
        <LinkedinButton size={"1.5em"} />
      </Grid>
      <Grid item />
      <Grid item >
        {!isSmallScreen && (
          <Typography variant="h5" sx={{ color: "#1A1A1A" }}>
            Suivez-nous pour nous aider
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

const Logo = () => {
  const theme = useTheme();
  return (
    <Button
      href="#"
      sx={{
        ...theme.mixins.toolbar,
        minWidth: "10em",
        maxWidth: { xs: "40vw", sm: "20vw" },
        padding: { xs: 1, md: 2 },
      }}
    >
      <img
        src={QualinoteLogo}
        alt="Logo Qualinote"
        style={{ width: "100%", maxHeight: "4.5em" }}
      />
    </Button>
  );
};

const Header = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <AppBar position="fixed" sx={{ background: "#FBFBFB" }}>
        <Toolbar>
          <Logo />
          {!isSmallScreen && <Actions />}
        </Toolbar>
      </AppBar>
      <Offset />
    </>
  );
};

export default Header;
