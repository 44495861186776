import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import ContactImage from '../../assets/images/Homepage/contact.jpg';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { useController, useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';

const Container = styled(Grid)(({ theme }) => ({
  width: '100%',
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  textAlign: 'center',
  width: '100%',
  backgroundColor: '#0A0A0A',
  padding: '1rem',
  [theme.breakpoints.down('md')]: {
    padding: '0.5rem',
  },
  '& > *': {
    color: '#FFF',
  },
  '&:hover': {
    backgroundColor: '#0A0A0A',
  },
}));

const LeftImage = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${ContactImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

const HomepageContact = (props) => {
  const theme = useTheme();
  var showLeftImage = useMediaQuery(theme.breakpoints.up('lg'));

  // For phone number validation - check if composed of '+' and numbers only
  const phoneRegExp = /^[+0-9].[0-9]+$/;
  const validator = object().shape({
    email: string().email('Email invalide').required('Email requis'),
    name: string().required('Nom requis'),
    phone: string()
      .matches(phoneRegExp, 'Numéro de téléphone invalide')
      .min(10, 'Numéro trop court')
      .max(13, 'Numéro trop long'),
    message: string().required('Le message ne peut pas être vide'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validator),
  });

  const emailField = useController({
    name: 'email',
    control,
    rules: { required: true },
    defaultValue: '',
  });

  const nameField = useController({
    name: 'name',
    control,
    rules: { required: true },
    defaultValue: '',
  });

  const phoneField = useController({
    name: 'phone',
    control,
    rules: { required: false },
    defaultValue: '',
  });

  const messageField = useController({
    name: 'message',
    control,
    rules: { required: true },
    defaultValue: '',
  });

  const onSubmit = (data) => {
    axios
      .post(
        'https://1caviewz04.execute-api.eu-west-3.amazonaws.com/contact',
        data
      )
      .then((response) => {
        if (response.status === 200) {
          alert('Votre message a bien été envoyé !');
        } else {
          alert('Une erreur est survenue, veuillez réessayer plus tard.');
        }
      })
      .catch((error) => {
        console.log(error);
        alert('Une erreur est survenue, veuillez réessayer plus tard.');
      });
  };

  return (
    <Container container id='contact-section'>
      {showLeftImage && <LeftImage item xs />}
      <Grid item xs padding={{ xs: 4, md: 8 }}>
        <Typography variant='body1' textAlign='center' marginBottom={1}>
          LE PROJET VOUS INTÉRESSE ET VOUS VOULEZ ALLER PLUS LOIN AVEC NOUS ?
        </Typography>
        <Typography variant='h1' component='h2' textAlign='center'>
          Contactez-nous
        </Typography>
        <Box
          component='form'
          sx={{
            '.MuiTextField-root': { width: '100%', marginBottom: 2 },
          }}
          noValidate
          marginTop={{ xs: 4, md: 8 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            required
            id='name'
            label='NOM'
            type='text'
            autoComplete='name'
            name={nameField.field.name}
            value={nameField.field.value}
            onChange={nameField.field.onChange}
            error={!!errors?.name}
            helperText={errors?.name?.message}
          />
          <TextField
            label='NUMERO DE TELEPHONE'
            id='phone'
            type='tel'
            autoComplete='tel'
            name={phoneField.field.name}
            value={phoneField.field.value}
            onChange={phoneField.field.onChange}
            error={!!errors?.phone}
            helperText={errors?.phone?.message}
          />
          <TextField
            required
            label='EMAIL'
            id='email'
            type='email'
            autoComplete='email'
            name={emailField.field.name}
            value={emailField.field.value}
            onChange={emailField.field.onChange}
            error={!!errors?.email}
            helperText={errors?.email?.message}
          />
          <TextField
            multiline
            required
            label='MESSAGE'
            id='message'
            type='text'
            minRows={4}
            maxRows={10}
            name={messageField.field.name}
            value={messageField.field.value}
            onChange={messageField.field.onChange}
            error={!!errors?.message}
            helperText={errors?.message?.message}
          />
          <SubmitButton type='submit'>
            <Typography variant='body1'>ENVOYER</Typography>
          </SubmitButton>
        </Box>
      </Grid>
    </Container>
  );
};

export default HomepageContact;
