import { IconButton } from '@mui/material';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  YoutubeIcon,
  TikTokIcon
} from '../Icons/SocialsIcons';

const facebookLink = 'https://www.facebook.com/people/Qualinote/61556667803878/';
const linkedinLink = 'https://www.linkedin.com/company/qualinote/';
const youtubeLink = 'Future link to Qualinote Youtube channel';
const instagramLink = 'https://www.instagram.com/qualinote/';
const tiktokLink = 'https://www.tiktok.com/@qualinote';

const SocialButton = (props) => {
  return (
    <IconButton
      disabled={props.disabled}
      edge={props.edge}
      target='_blank'
      href={props.link}
      sx={{margin: 0, padding: 0}}
    >
      <props.icon sx={{ fontSize: props.size }} disabled={props.disabled}/>
    </IconButton>
  );
};

const FacebookButton = (props) => {
  return <SocialButton icon={FacebookIcon} link={facebookLink} {...props} />;
};

const LinkedinButton = (props) => {
  return <SocialButton icon={LinkedInIcon} link={linkedinLink} {...props} />;
};

const YoutubeButton = (props) => {
  return <SocialButton icon={YoutubeIcon} link={youtubeLink} {...props} />;
};

const InstagramButton = (props) => {
  return <SocialButton icon={InstagramIcon} link={instagramLink} {...props} />;
};

const TikTokButton = (props) => {
  return <SocialButton icon={TikTokIcon} link={tiktokLink} {...props} />;
};

export { FacebookButton, LinkedinButton, YoutubeButton, InstagramButton, TikTokButton };
