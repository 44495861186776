import {
  Box,
  Typography,
  useMediaQuery,
} from '@mui/material';
import SocialImage from '../../assets/images/Homepage/social.png';
import { FacebookButton, InstagramButton, LinkedinButton, TikTokButton, YoutubeButton } from '../UI/Buttons/SocialsButtons';

const styles = {
  root: {
    sx: {
      background: '#3A8CAB',
      position: 'relative',
    },
    padding: { xs: 3, md: 4, lg: 6 },
  },
  imageBox: {
    sx: { position: 'absolute', height: 0, width: 0, left: '5%', top: -60 },
  },
};

const SocialLink = (props) => {
  return (
    <Box
      justifyContent='flex-end'
      display='flex'
      alignItems='center'
      marginTop={{ xs: 1, md: 2 }}
      gap={{ xs: 1.5, md: 2 }}
    >
      {props.children}
      <Typography
        variant='h4'
        component='p'
        color={props.disabled ? 'lightgrey' : '#FFF'}
        width='52.5%'
      >
        {props.text}
      </Typography>
    </Box>
  );
};

const HomepageSocial = (props) => {
  const isLargeScreen = useMediaQuery('(min-width:1600px)');
  const marginTop = isLargeScreen ? 14 : 0;

  return (
    <Box {...styles.root} marginTop={marginTop}>
      {isLargeScreen && (
        <Box {...styles.imageBox}>
          <img height='255px' src={SocialImage} alt='Social Links' />
        </Box>
      )}
      <Typography
        textAlign='center'
        color='#FFF'
        variant='h2'
        marginBottom={{ xs: 2, md: 4 }}
      >
        Un simple clic pour nous aider et suivre nos aventures
      </Typography>
      <SocialLink text='Linkedin'>
        <LinkedinButton size={{ xs: '2rem', md: '3rem' }} />
      </SocialLink>
      <SocialLink text='Facebook'>
        <FacebookButton size={{ xs: '2rem', md: '3rem' }} />
      </SocialLink>
      <SocialLink text='Instagram'>
        <InstagramButton size={{ xs: '2rem', md: '3rem' }} />
      </SocialLink>
      <SocialLink text='TikTok'>
        <TikTokButton size={{ xs: '2rem', md: '3rem' }} />
      </SocialLink>
    </Box>
  );
};

export default HomepageSocial;
