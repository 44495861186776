import { Box, Typography, styled } from '@mui/material';

const Video = styled('iframe')(({ theme }) => ({
  width: '50vw',
  aspectRatio: '16/9',
  [theme.breakpoints.down('lg')]: {
    width: '75vw',
  },
  [theme.breakpoints.down('md')]: {
    width: '85vw',
  },
}));

const HomepageVideo = () => {
  return (
    <>
      <Typography variant='h2' textAlign='center' marginTop={{ xs: 4, md: 8 }}>
        C’est quoi Qualinote en 2 minutes ?
      </Typography>
      <Box textAlign='center' marginTop={3}>
        <Video
          src='https://www.youtube.com/embed/9n5G0qFBsHM'
          title='YouTube video player'
        ></Video>
      </Box>
    </>
  );
};

export default HomepageVideo;
