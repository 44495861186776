import { Grid, Typography, Button } from "@mui/material";

const HomepageTopHero = (props) => {
  return (
    <Grid
      container
      columns={{ xs: 4, md: 8 }}
      padding={{ xs: 4, md: 8 }}
      spacing={{ xs: 2, md: 0 }}
      style={props.style}
    >
      <Grid item xs={4} md={5}>
        <Typography
          marginBottom={{ xs: 1, md: 2 }}
          variant='body1'
          component='p'
          color='#1C1C1C'
        >
          L'investissement immobilier vous intéresse ?
        </Typography>
        <Typography
          variant='h1'
          component='h1'
          color='#141414'
          style={{ maxWidth: 600 }}
        >
          Qualinote le futur service immobilier pour vous aider à faire le choix
          qui <span style={{ color: '#3A8CAB' }}>VOUS</span> convient
        </Typography>
        <Typography
          marginTop={{ xs: 1, md: 2 }}
          variant='body1'
          component='p'
          color='#1C1C1C'
        >
          Trouvez votre prochain investissement au travers de la jungle
          immobilière
        </Typography>
      </Grid>
      <Grid item xs />
      {/* <Grid
        item
        xs={4}
        md={2}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          variant='contained'
          color='primary'
          size='large'
          style={{ minWidth: 220, maxWidth: 220 }}
        >
          decouvrez notre experience
        </Button>
      </Grid> */}
    </Grid>
  );
};

export default HomepageTopHero;
