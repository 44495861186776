import {
  Box,
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from '@mui/material';
import './App.css';
import Header from './components/Header/Header';
import Homepage from './components/Homepage/Homepage';

function App() {
  var theme = createTheme({
    palette: {
      // primary: {
      //   main: '#E33E7F',
      // },
    },
    typography: {
      allVariants: {
        fontFamily: 'Poppins, sans-serif',
      },
      h1: {
        color: '#141414',
        fontSize: '2.25rem',
        fontWeight: 700,
      },
      h2: {
        color: '#141414',
        fontSize: '2rem',
        fontWeight: 700,
        lineHeight: 1.125,
      },
      h3: {
        color: '#000',
        fontSize: '1.875rem',
        fontWeight: 700,
        lineHeight: 1.25,
      },
      h4: {
        fontSize: '1.5rem',
        fontWeight: 600,
        lineHeight: 1.5625,
      },
      h5: {
        color: '#333',
        fontSize: '1.125rem',
        fontWeight: 700,
      },
      body1: {
        color: '#1B1B1B',
        fontSize: '1.125rem',
        fontWeight: 400,
      },
    },
  });
  theme = responsiveFontSizes(theme, { factor: 15 });

  theme.typography.body1 = {
    ...theme.typography.body1,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  };

  theme.typography.h5 = {
    ...theme.typography.h5,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  };

  return (
    <Box sx={{overflowX: 'hidden'}}>
      <ThemeProvider theme={theme}>
        <Header />
        <Homepage />
      </ThemeProvider>
    </Box>
  );
}

export default App;
