import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import ProjectImage from '../../assets/images/Homepage/computer.png';
import AnalyseImage from '../../assets/images/Homepage/tablet.png';
import HandHouseImage from '../../assets/images/Homepage/hand-house.png';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

const smallCardsInfoPrivate = [
  {
    bg: '#C9E2EB',
    title: 'VOTRE PROJET',
    body: 'Trouvez des annonces immobilières adaptées à vos valeurs et vos besoins uniques',
    img: ProjectImage,
    dashedLine: {md: '200px'},
  },
  {
    bg: '#C9EBE1',
    title: 'EN TOUTE SÉRÉNITÉ',
    body: 'Maximisez la rentabilité de votre investissement tout en évaluant les risques associés',
    img: AnalyseImage,
    dashedLine: {md: '1400px', lg: '1000px'},
  },
  {
    bg: '#E8EBC9',
    title: 'SANS RIEN LOUPER',
    body: 'Soyez le premier à être notifié des nouvelles annonces immobilières correspondant à votre projet',
    img: HandHouseImage,
    dashedLine: {md: '2500px', lg: '1700px'},
  },
];

const smallCardsInfoPro = [
  {
    bg: '#C9E2EB',
    title: 'RECHERCHE CENTRALISÉE',
    body: 'Recherchez dans toutes les annonces postées sur les plus grandes plateformes en ligne en une seule recherche',
    img: ProjectImage,
    dashedLine: {md: '200px'},
  },
  {
    bg: '#C9EBE1',
    title: 'ANALYSES COMPLÈTES',
    body: 'Visualisez le potentiel des nouvelles opportunités en un instant grâce à nos analyses complètes et personnalisables',
    img: AnalyseImage,
    dashedLine: {md: '1400px', lg: '1000px'},
  },
  {
    bg: '#E8EBC9',
    title: 'NOTIFICATIONS EN TEMPS RÉEL',
    body: 'Soyez le premier informé des nouvelles opportunités immobilières correspondantes à vos critères',
    img: HandHouseImage,
    dashedLine: {md: '2500px', lg: '1700px'},
  },
];

const SmallCard = styled(Box)(({ theme }) => ({
  borderRadius: 'var(--2, 1rem)',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  padding: 'var(--4, 2rem)',
  height: '30rem',
  maxWidth: '20rem',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: 'auto',
    padding: 'var(--2, 1rem)',
  },
}));

const DashedLine = styled(Box)(({ theme }) => ({
  zIndex: -10,
  position: 'absolute',
  top: '100%',
  width: '10px',
  backgroundPosition: '0px 0',
  backgroundSize: '8px 15px',
  backgroundRepeat: 'repeat-y',
}));

const HomepageSmallCards = (props) => {
  const smallCardsInfo = props.isPro
    ? smallCardsInfoPro
    : smallCardsInfoPrivate;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      container
      justifyContent='space-between'
      alignItems='center'
      columns={{ xs: 4, sm: 4, md: 12 }}
      rowGap={4}
    >
      {smallCardsInfo.map((info, index) => (
        <Grid
          item
          xs={4}
          md={3.5}
          lg={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
          }}
          key={index}
        >
          <SmallCard sx={{ background: info.bg }}>
            <Typography component='h3' variant='h3'>
              {info.title}
            </Typography>
            <Typography
              mt={{ xs: 1, sm: 2 }}
              component='p'
              variant='body1'
              lineHeight={{ xs: '1.375rem', md: '1.55rem' }}
            >
              {info.body}
            </Typography>
            {!isSmallScreen && (
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                }}
              >
                <img
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                  src={info.img}
                  alt={info.title}
                />
              </Box>
            )}
          </SmallCard>
          {!isSmallScreen && (
            <DashedLine
              height={info.dashedLine}
              sx={{
                backgroundImage: `linear-gradient(${info.bg} 8px, ${info.bg} 8px, transparent 8px)`,
              }}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default HomepageSmallCards;
