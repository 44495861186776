import { Box, Button, Fade, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import HomepageSmallCards from './HomepageSmallCards';
import HomepageBigCards from './HomepageBigCards';

const SelectionButton = (props) => {
  return (
    <Button
      {...props}
      sx={{ width: { xs: '100%', md: 'auto' } }}
      variant='contained'
      color='primary'
      size='large'
      xs={4}
    >
      {props.children}
    </Button>
  );
};

const HomepageMain = () => {
  const [isPro, setIsPro] = useState(false);
  const [fade, setFade] = useState(true);
  const [showProText, setShowProText] = useState(false);

  const handleSetPro = (value) => {
    setIsPro(value);
    setFade(false);
  };

  useEffect(() => {
    setFade(false);
    const t = setTimeout(() => {
      setFade(true);
      setShowProText(isPro);
    }, 400);

    return () => clearTimeout(t);
  }, [isPro]);

  return (
    <Box margin={{ xs: 4, md: 8 }}>
      <Grid
        container
        justifyContent='center'
        columnGap={4}
        rowGap={4}
        columns={{ xs: 4, md: 8 }}
      >
        <SelectionButton disabled={!isPro} onClick={() => handleSetPro(false)}>
          Je suis un particulier
        </SelectionButton>
        <SelectionButton disabled={isPro} onClick={() => handleSetPro(true)}>
          Je suis un professionnel de l’immobilier
        </SelectionButton>
      </Grid>
      <Box my={{ xs: 4, md: 8 }} display='flex' justifyContent='center'>
        <Typography
          variant='h2'
          color='#3A8CAB'
          sx={{ borderBottom: { xs: '0.25rem solid', md: '0.35rem solid' } }}
        >
          Qualinote c’est...
        </Typography>
      </Box>
      <Fade in={fade} timeout={350}>
        <Box margin={{xs: 0, sm: 8}}>
          <HomepageSmallCards isPro={showProText} />
          <HomepageBigCards isPro={showProText} />
        </Box>
      </Fade>
    </Box>
  );
};

export default HomepageMain;
