import BackgroundImage from '../../assets/images/Homepage/building-background.png';
import BackgroundImageSmall from '../../assets/images/Homepage/building-background-small.jpg';
import HomepageTopCard from './HomepageTopCard';
import HomepageTopHero from './HomepageTopHero';
import { Box } from '@mui/material';

const styles = {
  background: {
    background: '#FEFDFB',
    backgroundPosition: {xs: 'bottom left', md: 'bottom right'},
    backgroundRepeat: 'no-repeat',
    paddingBottom: '2.25rem',
    backgroundImage: {xs: `url(${BackgroundImageSmall})`, md : `url(${BackgroundImage})`},
    backgroundSize: {xs: 'cover', md: 'contain'}
  }
}

const HomepageTop = () => {
  return (
    <Box sx={styles.background}>
      <HomepageTopHero style={{ maxWidth: 1200 }} />
      <HomepageTopCard style={{ maxWidth: 1200 }} />
    </Box>
  );
};

export default HomepageTop;
