import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import ProjectImage from '../../assets/images/Homepage/computer.png';
import AnalyseImage from '../../assets/images/Homepage/tablet.png';
import HandHouseImage from '../../assets/images/Homepage/hand-house.png';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

const bigCardInfoPrivate = [
  {
    bg: '#C9E2EB',
    title: 'VOTRE PROJET',
    body: "Découvrez une expérience immobilière personnalisée avec Qualinote. Nous comprenons que chaque investisseur a des besoins uniques. C'est pourquoi notre plateforme vous offre une recherche ciblée selon vos critères spécifiques.",
    subtitle1: 'Recherche ciblée',
    subbody1:
      'Trouvez les propriétés qui correspondent à vos attentes en précisant la localisation, le type de bien, le budget, etc. Économisez du temps en ne voyant que les annonces pertinentes pour vous.',
    subtitle2: 'Recommandations sur mesure',
    subbody2:
      'Notre algorithme avancé s’adapte à vos préférences pour vous fournir des recommandations personnalisées. Découvrez les annonces correspondantes aux critères qui comptent pour vous.',
    img: ProjectImage,
    subtitleColor: '#3A8CAB',
  },
  {
    bg: '#C9EBE1',
    title: 'EN TOUTE SÉRÉNITÉ',
    body: 'Avec Qualinote, vous pouvez investir en toute confiance grâce à notre approche axée sur la sécurité. Nous vous offrons les outils nécessaires pour évaluer les risques et enfin allier rentabilité avec sérénité.',
    subtitle1: 'Évaluation des risques',
    subbody1:
      "Identifiez les risques potentiels liés à un investissement grâce à notre analyse des risques. Nous prenons en compte des facteurs tels que l'emplacement, les caractéristiques du bien et les tendances du marché pour vous aider à prendre des décisions éclairées.",
    subtitle2: 'Conseils personnalisés',
    subbody2:
      'Des questions sur l’investissement ? Ou un doute sur votre situation ? Que vous soyez un investisseur chevronné ou un débutant, notre chatbot répondra à vos questions 24h/24.',
    img: AnalyseImage,
    subtitleColor: '#2FAC87',
  },
  {
    bg: '#E8EBC9',
    title: 'SANS RIEN LOUPER',
    body: 'Ne manquez plus d’opportunités ! Notre plateforme vous notifie en temps réel des nouvelles annonces, vous donnant ainsi un avantage concurrentiel non négligeable pour l’achat de votre bien.',
    subtitle1: 'Alertes instantanées',
    subbody1:
      "Recevez des alertes instantanées dès qu'une nouvelle annonce correspondant à vos critères est publiée. Soyez parmi les premiers à réagir et à contacter les vendeurs, augmentant ainsi vos chances de saisir les opportunités immobilières dès qu'elles se présentent.",
    subtitle2: 'Alertes intelligentes',
    subbody2:
      'Avec notre système d’alerte intelligente, nous vous préviendrons seulement des annonces intéressantes correspondantes à votre projet. Pas d’arnaque ou de doublons, uniquement de vraies opportunités !',
    img: HandHouseImage,
    subtitleColor: '#C3B62F',
  },
];

const bigCardInfoPro = [
  {
    bg: '#C9E2EB',
    title: 'RECHERCHE CENTRALISÉE',
    body: 'Avec Qualinote, ne perdez plus votre temps à sauter de plateforme en plateforme ! Nous regroupons pour vous, et au même endroit, les annonces des plus grandes plateformes immobilières françaises.',
    subtitle1: 'Une seule recherche',
    subbody1:
      'Parcourez en un seul en endroit les annonces postées sur les plus grandes plateformes immobilières françaises : LeBonCoin, Pap, SeLoger, et bien d’autres.',
    subtitle2: 'Des filtres innovants',
    subbody2:
      'Passez au grain les annonces existantes grâce à notre sélection de filtres comme la rentabilité, la localisation, le besoin de travaux, et bien plus.',
    img: ProjectImage,
    subtitleColor: '#3A8CAB',
  },
  {
    bg: '#C9EBE1',
    title: 'ANALYSES COMPLÈTES',
    body: 'Nous proposons une analyse complète du bien spécialement pour l’investissement locatif. Rentabilité, travaux, sécurité, ... nous analysons pour vous plusieurs dizaines de paramètres sur lesquels vous pouvez vous appuyer.',
    subtitle1: 'Une analyse 360°',
    subbody1:
      'Basées sur quatre piliers, nos analyses vous permettent de voir en détail le potentiel d’un bien. Visualisez d’un coup d’œil la rentabilité immédiate, le potentiel d’appréciation, le potentiel du quartier, et la sécurité de l’investissement.',
    subtitle2: 'La note Qualinote',
    subbody2:
      'Comparez simplement le potentiel de plusieurs annonces ou biens grâce à la note Qualinote, regroupant notre analyse en une seule note. La note est paramétrable pour respecter au mieux les critères qui comptent pour vous.',
    img: AnalyseImage,
    subtitleColor: '#2FAC87',
  },
  {
    bg: '#E8EBC9',
    title: 'NOTIFICATIONS EN TEMPS RÉEL',
    body: 'Avec Qualinote, vous ne manquerez aucune opportunité immobilière. Notre plateforme vous permet de rester informé en temps réel des nouvelles annonces, vous donnant ainsi un avantage concurrentiel pour trouver les meilleures opportunités.',
    subtitle1: 'Alertes instantanées',
    subbody1:
      "Recevez des alertes instantanées dès qu'une nouvelle annonce correspondant à vos critères est publiée. Soyez parmi les premiers à réagir et à contacter les vendeurs, augmentant ainsi vos chances de saisir les opportunités immobilières dès qu'elles se présentent.",
    subtitle2: 'Veille automatisée',
    subbody2:
      'Grâce à notre système de veille automatisée, Qualinote recherche en continu les nouvelles annonces immobilières correspondant à vos critères. ',
    img: HandHouseImage,
    subtitleColor: '#C3B62F',
  },
];

const BigCard = styled(Grid)(({ theme }) => ({
  position: 'relative',
  borderRadius: '1.25rem',
  boxShadow: ' 0px 1px 10px 0px rgba(0, 0, 0, 0.10)',
  padding: '3rem 4rem 4rem 4rem',
  [theme.breakpoints.down('md')]: {
    padding: '2rem 2rem 2rem 2rem',
  },
}));

const SubCard = styled(Grid)(({ theme }) => ({
  borderRadius: '1.25rem',
  background: '#FFF',
  padding: '2rem 3rem',
  [theme.breakpoints.down('md')]: {
    padding: '1rem 1.5rem',
  },
}));

const HomepageBigCards = (props) => {
  const bigCardInfo = props.isPro ? bigCardInfoPro : bigCardInfoPrivate;

  const theme = useTheme();

  var imageSize = 270;
  var imageRight = imageSize - imageSize / 4;
  var imageTop = imageSize / 3.6;
  if (useMediaQuery(theme.breakpoints.down('lg'))) {
    imageSize = 200;
    imageRight = imageSize - imageSize / 4;
    imageTop = imageSize / 3.6;
  }
  if (useMediaQuery(theme.breakpoints.down('sm'))) {
    imageSize = 150;
    imageRight = imageSize;
    imageTop = imageSize / 1.33;
  }

  return (
    <Grid
      container
      marginTop={{ xs: 20, sm: 12, lg: 20 }}
      rowGap={{ xs: 18, md: 14 }}
    >
      {bigCardInfo.map((info, index) => (
        <BigCard item sx={{ background: info.bg }} key={index}>
          <Box
            sx={{
              position: 'absolute',
              height: 0,
              width: 0,
              right: `${imageRight}px`,
              top: `-${imageTop}px`,
            }}
          >
            <img src={info.img} alt={info.title} width={imageSize} />
          </Box>
          <Typography component='h3' variant='h3'>
            {info.title}
          </Typography>
          <Typography
            component='p'
            variant='body1'
            maxWidth={{xs: '100%', sm: '80%'}}
            marginY={{ xs: 2, sm: 3 }}
          >
            {info.body}
          </Typography>

          <Grid
            container
            alignItems='stretch'
            justifyContent='space-between'
            columns={{ xs: 4, md: 8,  lg: 12 }}
            gap={{ xs: 2, md: 4 }}
            marginTop={{ xs: 4, md: 6, lg: 8}}
          >
            <SubCard item xs={4} sm={8} lg={5.75}>
              <Typography
                component='h4'
                variant='h4'
                sx={{ color: info.subtitleColor }}
                marginBottom={{ xs: 1, sm: 2 }}
              >
                {info.subtitle1}
              </Typography>
              <Typography component='p' variant='body1'>
                {info.subbody1}
              </Typography>
            </SubCard>
            <SubCard item xs={4} sm={8} lg={5.75}>
              <Typography
                component='h4'
                variant='h4'
                sx={{ color: info.subtitleColor }}
                marginBottom={{ xs: 1, sm: 2 }}
              >
                {info.subtitle2}
              </Typography>
              <Typography component='p' variant='body1'>
                {info.subbody2}
              </Typography>
            </SubCard>
          </Grid>
        </BigCard>
      ))}
    </Grid>
  );
};

export default HomepageBigCards;
