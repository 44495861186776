import { Typography, Grid, Box } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import IconSearch from '../../assets/images/Homepage/search-engine-icon.svg';
import IconAnalyse from '../../assets/images/Homepage/analysis-icon.svg';
import IconMeasure from '../../assets/images/Homepage/evualuate-icon.svg';

const ItemsData = [
  {
    img: IconSearch,
    title: 'Moteur de recherche',
    description:
      'Parcourez les annonces des plus grandes plateformes françaises en un seul clic',
  },
  {
    img: IconAnalyse,
    title: 'Analyse complète',
    description:
      'Examinez une annonce et son potentiel grâce à des analyses compréhensibles et détaillées',
  },
  {
    img: IconMeasure,
    title: 'Évaluation sur mesure',
    description:
      'Personnalisez votre recherche en intégrant les critères qui comptent pour vous',
  },
];

const BigCard = styled(Box)(({ theme }) => ({
  background: 'var(--White, #FBFBFB)',
  borderRadius: 'var(--3, 1.5rem)',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
}));

const Icon = styled('img')(({ theme }) => ({
  width: 'var(--8, 4rem)',
  height: 'var(--8, 4rem)',
  [theme.breakpoints.down('md')]: {
    width: '2.25rem',
    height: '2.25rem',
  },
}));

const HomepageTopCard = (props) => {
  return (
    <BigCard
      style={{ ...props.style }}
      marginX={{ xs: 4, md: 8 }}
      marginY={{ xs: 2, md: 2 }}
    >
      <Grid
        container
        columns={{ xs: 4, md: 12 }}
        paddingX={{ xs: 4, md: 8 }}
        paddingY={{ xs: 2, md: 4 }}
        spacing={{ xs: 2, md: 2 }}
        gap={{ xs: 2, md: 0 }}
      >
        {ItemsData.map((item, index) => (
          <Grid item xs={4} md={4} key={index}>
            <Icon src={item.img} alt={item.title} />
            <Typography
              variant='h5'
              component='h5'
              color='#141414'
              marginBottom={{ xs: 2, md: 2 }}
              marginTop={{ xs: 2, md: 2 }}
            >
              {item.title}
            </Typography>
            <Typography variant='body1' component='p' color='#1C1C1C'>
              {item.description}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </BigCard>
  );
};

export default HomepageTopCard;
