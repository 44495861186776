import HomepageTop from './HomepageTop';
import HomepageVideo from './HomepageVideo';
import HomepageMain from './HomepageMain';
import HomepageSocial from './HomepageSocial';
import HomepageContact from './HomepageContact';

const Homepage = () => {
  return (
    <>
      <HomepageTop />
      {/* <HomepageVideo /> */}
      <HomepageMain />
      <HomepageSocial />
      <HomepageContact />
    </>
  );
};

export default Homepage;
